@import 'src/app/style/material';
@import 'src/app/style/form';
@import 'src/app/style/button';
@import 'src/app/style/sticky-header';
@import 'src/app/style/sidenav';
@import 'src/app/style/utility/utility';
@import 'src/app/style/utility/variables';

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
}

[hidden] {
  display: none !important;
}

/* Scrollbar */

/* Width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 10px;
}

body {
  margin: 0;
}

.main-container {
  input:focus,
  input:active,
  input:hover,
  select:focus,
  select:active,
  select:hover,
  textarea:focus,
  textarea:active,
  textarea:hover {
    border: none;
  }

  // button:hover,
  // button:focus,
  // button:active,
  .eis-btn-primary:hover,
  .eis-btn-primary:focus,
  .eis-btn-primary:active {
    background-color: unset;
    outline: unset;
    border: unset;
    color: unset;
  }
}

a {
  color: #5738ff !important;
}

.cdk-overlay-backdrop {
  z-index: 0 !important;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

global-footer {
  display: block;
  padding: 25px;
}

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

lib-ngx-deeplinker {
  margin: 0 !important;
  display: block;
  text-align: right;

  button {
    background-color: #fff;
    color: $primary-color;
    border: 1px solid $primary-color;
  }

  ::ng-deep button {
    height: 26px !important;
    padding: 4px 20px !important;
  }
}

.a {
  fill: none !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
  background-color: #d8dadc;
  color: #999;
  opacity: 1;

  &:hover {
    opacity: 1;
    border: 1px solid #999;
  }
}

button.mat-focus-indicator.mat-icon-button:hover,
button.mat-focus-indicator.mat-icon-button:focus {
  border: none !important;
}

.map-container {
  margin: 2rem auto 1rem;
}

.pac-container {
  min-width: 400px !important;
}

.section-header-container {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 25px;
  border-bottom: 1px #ddd solid;
  padding: 0 0 1rem;
}

.section-header {
  color: $primary-color;
  font-weight: 400;
}

.page-container {
  padding: 0 2rem 2rem;
}

map-view {
  height: 20rem;
}

th,
td {
  padding: 0 2rem;
}

.mat-header-row {
  background-color: #fff;

  th {
    color: $primary-color;
    font-weight: 600;
    font-size: 14px;
    max-width: 11rem;
  }
}

.mat-button-disabled {
  background-color: initial !important;
  border: initial !important;
}

.translated-paragraph {
  white-space: pre-wrap;
}
