@import 'utility/variables';

// .icon-btn {
//   margin-right: 15px !important;
//   font-weight: normal !important;
//   font-size: 1.125rem;

//   .mat-button-wrapper > * {
//     display: inline-block;
//   }

//   .mat-icon {
//     margin-right: 5px;
//     width: 15px;
//     font-size: 15px;
//   }

//   .btn-spinner {
//     margin-right: 5px;

//     circle {
//       stroke: white;
//     }
//   }
// }

.icon-btn {
  margin-right: 15px;
  font-weight: normal;
  font-size: 13px;
  padding: 0 15px;
  > * {
    display: inline-block;
    vertical-align: middle;
  }

  .mat-icon {
    margin-right: 8px;
    height: 18px;
    width: 15px;
    font-size: 18px;
  }

  .btn-spinner {
    margin-right: 5px;
    circle {
      stroke: white;
    }
  }
}

.mat-stroked-button {
  border: 1px solid !important;
}

.primary-btn {
  border-color: $primary-color;
  background-color: #fff;
  color: $primary-color;
  font-weight: normal;
  font-size: 0.8rem !important;

  &:hover {
    background-color: $button-hover;
  }
}

.primary-btn[disabled],
.primary-btn:disabled {
  border-color: #bbb;
  background-color: #aaa;
}

.secondary-btn {
  color: $primary-color !important;
  border-color: rgb(70 30 125 / 60%) !important;
  background-color: #fff !important;
  font-weight: normal !important;
  font-size: 13px !important;

  &:hover {
    background-color: rgb(70 30 125 / 20%) !important;
  }
}

.wide-btn {
  padding: 0 30px !important;
}

.tooltip-btn-wrapper {
  display: inline-block;
}

.mat-tooltip {
  background: rgb(0 0 0 / 70%);
  font-size: 13px;
}
